const status = {
  IN_PROGRESS: 0,
  SUCCESS: 1,
  FAILURE: 2,
};

export default status;

export const LOCALSTORAGE_ITEMS = {
  cartItem: "cartItem",
};
